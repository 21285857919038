export enum ActionTypes {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  LOGOUT_CLEAR_STATE = "LOGOUT_CLEAR_STATE",
  REFRESH_LOGIN = "REFRESH_LOGIN",
  ME = "ME",
  PASSWORD_RESET = "PASSWORD_RESET",
  SEND_PASSWORD_RESET_EMAIL = "SEND_PASSWORD_RESET_EMAIL",
  VERIFY_PASSWORD_RESET_TOKEN = "VERIFY_PASSWORD_RESET_TOKEN",
  IMPERSONATE = "IMPERSONATE",
}
