export enum MutationTypes {
  STUDENT_ONBOARDING_STEP_LOADING = "STUDENT_ONBOARDING_STEP_LOADING",
  STUDENT_ONBOARDING_STEP_LOADED = "STUDENT_ONBOARDING_STEP_LOADED",
  COMPANY_ONBOARDING_STEP_LOADING = "COMPANY_ONBOARDING_STEP_LOADING",
  COMPANY_ONBOARDING_STEP_LOADED = "COMPANY_ONBOARDING_STEP_LOADED",
  UPDATE_STUDENT_LOADING = "UPDATE_STUDENT_LOADING",
  UPDATE_STUDENT_LOADED = "UPDATE_STUDENT_LOADED",
  UPDATE_USER_LOADING = "UPDATE_USER_LOADING",
  UPDATE_USER_LOADED = "UPDATE_USER_LOADED",
  DELETE_USER_LOADING = "DELETE_USER_LOADING",
  DELETE_USER_LOADED = "DELETE_USER_LOADED",
  PASSWORD_CHANGE_LOADING = "PASSWORD_CHANGE_LOADING",
  PASSWORD_CHANGE_LOADED = "PASSWORD_CHANGE_LOADED",
  UNIVERSITY_ONBOARDING_STEP_LOADING = "UNIVERSITY_ONBOARDING_STEP_LOADING",
  UNIVERSITY_ONBOARDING_STEP_LOADED = "UNIVERSITY_ONBOARDING_STEP_LOADED",
  STUDENT_ONBOARDING_NICKNAME_SUGGESTIONS = "STUDENT_ONBOARDING_NICKNAME_SUGGESTIONS",
  ZIP_CITY_LOADING = "ZIP_CITY_LOADING",
  ZIP_CITY_LOADED = "ZIP_CITY_LOADED",
  CLEAR_ONBOARDING_STATE = "CLEAR_ONBOARDING_STATE",
}
