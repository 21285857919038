export enum MutationTypes {
  CONFIGURATIONS_LOADING = "CONFIGURATIONS_LOADING",
  CONFIGURATIONS_LOADED = "CONFIGURATIONS_LOADED",
  CLEAR_FILES_FOR_KEY = "CLEAR_FILES_FOR_KEY",
  ADD_FILE_TO_QUEUE = "ADD_FILE_TO_QUEUE",
  UPLOAD_FILE_START = "UPLOAD_FILE_START",
  UPLOAD_FILE_COMPLETE = "UPLOAD_FILE_COMPLETE",
  UPLOADED_FILES_LOADING = "UPLOADED_FILES_LOADING",
  UPLOADED_FILES_LOADED = "UPLOADED_FILES_LOADED",
  DELETE_FILE_LOADING = "DELETE_FILE_LOADING",
  DELETE_FILE_LOADED = "DELETE_FILE_LOADED",
}
