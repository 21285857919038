export enum ActionTypes {
  STUDENT_ONBOARDING_STEP1 = "STUDENT_ONBOARDING_STEP1",
  STUDENT_ONBOARDING_STEP2 = "STUDENT_ONBOARDING_STEP2",
  STUDENT_ONBOARDING_STEP3 = "STUDENT_ONBOARDING_STEP3",
  STUDENT_ONBOARDING_STEP4 = "STUDENT_ONBOARDING_STEP4",
  STUDENT_ONBOARDING_STEP5 = "STUDENT_ONBOARDING_STEP5",
  STUDENT_ONBOARDING_STEP6 = "STUDENT_ONBOARDING_STEP6",
  COMPANY_ONBOARDING_STEP1 = "COMPANY_ONBOARDING_STEP1",
  COMPANY_ONBOARDING_STEP2 = "COMPANY_ONBOARDING_STEP2",
  COMPANY_ONBOARDING_STEP3 = "COMPANY_ONBOARDING_STEP3",
  COMPANY_ONBOARDING_STEP4 = "COMPANY_ONBOARDING_STEP4",
  UPDATE_USER = "UPDATE_USER",
  PASSWORD_CHANGE = "PASSWORD_CHANGE",
  UNIVERSITY_ONBOARDING_STEP1 = "UNIVERSITY_ONBOARDING_STEP1",
  UNIVERSITY_ONBOARDING_STEP2 = "UNIVERSITY_ONBOARDING_STEP2",
  UNIVERSITY_ONBOARDING_STEP3 = "UNIVERSITY_ONBOARDING_STEP3",
  UNIVERSITY_ONBOARDING_STEP4 = "UNIVERSITY_ONBOARDING_STEP4",
  CITY_BY_ZIP = "CITY_BY_ZIP",
  UPDATE_STUDENT = "UPDATE_STUDENT",
  DELETE_USER = "DELETE_USER",
}
