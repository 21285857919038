export enum MutationTypes {
  CHALLENGE_STEP_LOADING = "CHALLENGE_STEP_LOADING",
  CHALLENGE_STEP_LOADED = "CHALLENGE_STEP_LOADED",
  CHALLENGE_LOADING = "CHALLENGE_LOADING",
  CHALLENGE_LOADED = "CHALLENGE_LOADED",
  CLEAR_CURRENT_CHALLENGE = "CLEAR_CURRENT_CHALLENGE",
  ADD_EMPLOYEE_LOADING = "ADD_EMPLOYEE_LOADING",
  ADD_EMPLOYEE_LOADED = "ADD_EMPLOYEE_LOADED",
  EMPLOYEES_LOADING = "EMPLOYEES_LOADING",
  EMPLOYEES_LOADED = "EMPLOYEES_LOADED",
  DELETE_CHALLENGE_LOADING = "DELETE_CHALLENGE_LOADING",
  DELETE_CHALLENGE_LOADED = "DELETE_CHALLENGE_LOADED",
}
