export enum ActionTypes {
  BENEFITS = "BENEFITS",
  BRANCHES = "BRANCHES",
  COMPANY = "COMPANY",
  COMPANY_MATCHING = "COMPANY_MATCHING",
  CULTURAL_FITS = "CULTURAL_FITS",
  DASHBOARD = "DASHBOARD",
  JOB_POSTINGS = "JOB_POSTINGS",
  JOB_POSTING = "JOB_POSTING",
  JOB_REQUIREMENTS = "JOB_REQUIREMENTS",
  JOB_TYPE = "JOB_TYPE",
  KEYWORDS = "KEYWORDS",
  LANGUAGES = "LANGUAGES",
  LANGUAGE_LEVELS = "LANGUAGE_LEVELS",
  MATCH_JOB_POSTING = "MATCH_JOB_POSTING",
  MATCH_CHALLENGE = "MATCH_CHALLENGE",
  MATCH_STUDENT = "MATCH_STUDENT",
  MATCHING = "MATCHING",
  CHALLENGE = "CHALLENGE",
  CHALLENGE_PUBLIC = "CHALLENGE_PUBLIC",
  CHALLENGES = "CHALLENGES",
  CHALLENGE_TYPES = "CHALLENGE_TYPES",
  SKILLS = "SKILLS",
  SOFT_SKILLS = "SOFT_SKILLS",
  STUDENT = "STUDENT",
  ZIP_CITY_JOBS = "ZIP_CITY_JOBS",
}
