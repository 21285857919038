
import { replaceStack } from "@/helpers/replaceStack";
import { Options, prop, Vue } from "vue-class-component";

class Props {
  name = prop<string>({ required: true });
  url = prop<string>({ required: true });
}

@Options({})
export default class CompanyLogo extends Vue.with(Props) {
  replaceStack(url: string, stack: string): string {
    return replaceStack(url, stack);
  }
}
