export enum ActionTypes {
  SAVE_JOBPOSTING_STEP1 = "SAVE_JOBPOSTING_STEP1",
  SAVE_JOBPOSTING_STEP2 = "SAVE_JOBPOSTING_STEP2",
  SAVE_JOBPOSTING_STEP3 = "SAVE_JOBPOSTING_STEP3",
  JOBPOSTING = "JOBPOSTING",
  ADD_EMPLOYEE = "ADD_EMPLOYEE",
  DELETE_EMPLOYEE = "DELETE_EMPLOYEE",
  EMPLOYEES = "EMPLOYEES",
  DELETE_JOBPOSTING = "DELETE_JOBPOSTING",
}
