export enum MutationTypes {
  BENEFITS_LOADING = "BENEFITS_LOADING",
  BENEFITS_LOADED = "BENEFITS_LOADED",
  BRANCHES_LOADING = "BRANCHES_LOADING",
  BRANCHES_LOADED = "BRANCHES_LOADED",
  COMPANY_LOADING = "COMPANY_LOADING",
  COMPANY_LOADED = "COMPANY_LOADED",
  COMPANY_MATCHING_LOADING = "COMPANY_MATCHING_LOADING",
  COMPANY_MATCHING_LOADED = "COMPANY_MATCHING_LOADED",
  CULTURAL_FITS_LOADING = "CULTURAL_FITS_LOADING",
  CULTURAL_FITS_LOADED = "CULTURAL_FITS_LOADED",
  DASHBOARD_LOADING = "DASHBOARD_LOADING",
  DASHBOARD_LOADED = "DASHBOARD_LOADED",
  JOB_POSTING_LOADING = "JOB_POSTING_LOADING",
  JOB_POSTING_LOADED = "JOB_POSTING_LOADED",
  JOB_POSTINGS_LOADING = "JOB_POSTINGS_LOADING",
  JOB_POSTINGS_LOADED = "JOB_POSTINGS_LOADED",
  JOB_REQUIREMENTS_LOADING = "JOB_REQUIREMENTS_LOADING",
  JOB_REQUIREMENTS_LOADED = "JOB_REQUIREMENTS_LOADED",
  JOB_TYPES_LOADING = "JOB_TYPES_LOADING",
  JOB_TYPES_LOADED = "JOB_TYPES_LOADED",
  LANGUAGES_LOADING = "LANGUAGES_LOADING",
  LANGUAGES_LOADED = "LANGUAGES_LOADED",
  LANGUAGE_LEVELS_LOADING = "LANGUAGE_LEVELS_LOADING",
  LANGUAGE_LEVELS_LOADED = "LANGUAGE_LEVELS_LOADED",
  KEYWORDS_LOADING = "KEYWORDS_LOADING",
  KEYWORDS_LOADED = "KEYWORDS_LOADED",
  MATCH_LOADING = "MATCH_LOADING",
  MATCH_JOB_POSTING_LOADED = "MATCH_JOB_POSTING_LOADED",
  MATCH_CHALLENGE_LOADED = "MATCH_CHALLENGE_LOADED",
  MATCH_STUDENT_LOADED = "MATCH_STUDENT_LOADED",
  MATCHES_LOADING = "MATCHES_LOADING",
  MATCHES_LOADED = "MATCHES_LOADED",
  RESET_MATCHES = "RESET_MATCHES",
  CHALLENGE_LOADING = "CHALLENGE_LOADING",
  CHALLENGE_LOADED = "CHALLENGE_LOADED",
  CHALLENGES_LOADING = "CHALLENGES_LOADING",
  CHALLENGES_LOADED = "CHALLENGES_LOADED",
  CHALLENGE_TYPES_LOADING = "CHALLENGE_TYPES_LOADING",
  CHALLENGE_TYPES_LOADED = "CHALLENGE_TYPES_LOADED",
  SKILLS_LOADING = "SKILLS_LOADING",
  SKILLS_LOADED = "SKILLS_LOADED",
  SOFT_SKILLS_LOADING = "SOFT_SKILLS_LOADING",
  SOFT_SKILLS_LOADED = "SOFT_SKILLS_LOADED",
  STUDENT_LOADING = "STUDENT_LOADING",
  STUDENT_LOADED = "STUDENT_LOADED",
  ZIP_CITY_JOBS_LOADING = "ZIP_CITY_JOBS_LOADING",
  ZIP_CITY_JOBS_LOADED = "ZIP_CITY_JOBS_LOADED",
}
