
import { Options, prop, Vue } from "vue-class-component";

class Props {
  text = prop<string>({ required: true });
}

@Options({})
export default class NavDropdown extends Vue.with(Props) {
  declare $refs: {
    navDropdown: HTMLFormElement;
  };

  open = false;

  mounted(): void {
    this.open = false;
    document.body.addEventListener("click", this.handleClickAway);
  }

  unmounted(): void {
    document.body.removeEventListener("click", this.handleClickAway);
  }

  handleClickAway(event: Event): void {
    if (!this.$refs.navDropdown?.contains(event.target as HTMLElement)) {
      this.open = false;
    }
    this.$router.beforeEach(() => {
      this.open = false;
    });
  }
}
